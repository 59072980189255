//基础样式重置
html {
  font-size: 625%;;
  width: 100%;
  height: 100%;

  .appContainer {
    /*background: blue;*/
    padding: 4vw;
    /*max-width: 540px;*/
    /*margin: auto;*/
  }

  .appContainerAdmin {
    //padding: 4vw;
  }

  body,
  ul,
  ol,
  div,
  img,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input,
  textarea,
  p {
    //margin: 0;
    padding: 0;
    font-family: Roboto-Regular;
    font-size: 17px;
    box-sizing: border-box;
  }

  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .greyBtn {
    background: rgb(199, 199, 199) 10000%;
    color: black;
    font-size: 18px;
    height: 40px;
    border-radius: 6px;
    padding: 5px 20px;
  }

  .greyBtnBold {
    .greyBtn;
    font-weight: bold;
  }

  .whiteBtn {
    border: 1px solid #000;
    font-size: 18px;
    height: 40px;
    border-radius: 6px;
    padding: 0px 20px;
  }

  //酒红色
  .wineRed {
    color: rgba(146, 7, 132, 100);
  }

  .red {
    color: red;
  }

  //深蓝色
  .darkBlue {
    color: rgba(18, 43, 136, 100);
  }

  //浅蓝色
  .lightBlue {
    color: #1515FF;
  }

  //注释用灰色
  .hint {
    font-size: 14px;
    text-align: justify;
  }

  //注释用灰色
  .hintGray {
    color: rgba(128, 128, 128, 100);
    font-size: 14px;
    text-align: justify;
  }

  //注释用灰色
  .hintGrayItalic {
    color: rgba(128, 128, 128, 100);
    font-size: 14px;
    font-style: italic;
  }

  .gray {
    background: rgba(128, 128, 128, 100);
  }

  .bold {
    font-weight: bold;
  }


  .ant-radio-inner {
    border-width: 2px;
    border-color: rgba(128, 128, 128, 100);
  }

  .ant-radio-inner::after {
    //top: 2px;
    //left: 2px;
  }

  .flexGrow1 {
    display: flex;
    flex-grow: 1;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .mustEdit {
    margin-right: 4px;

  }

  .font17 {
    font-size: 17px;
  }

  .font18 {
    font-size: 18px;
  }

  .font20 {
    font-size: 20px;
  }

  .font28 {
    font-size: 28px;
  }

  .link {
    color: #1890ff;
    cursor: pointer;
  }

  label.required:before {
    color: #ff4d4f;
    content: "*";
    padding-right: 5px;
  }

  label.noRequired:before {
    color: #ff4d4f;
    content: "*";
    visibility: hidden;
    padding-right: 5px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-right: 8px;
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    margin-right: 8px;
    margin-left: 0;
  }

  .ant-input {
    border-color: #9C8080;
    font-size: 18px;

    &::placeholder {
      color: rgba(128, 128, 128, 100);
      font-size: 16px;
      font-style: italic;
    }
  }

  .ant-input-number {
    border-color: #9C8080;
    font-size: 18px;
  }

  .ant-checkbox-inner, .ant-checkbox-input {
    border-color: #9C8080;
  }

  .ant-card-bordered {
    border-color: rgba(128, 128, 128, 100);
  }

  .ant-form-item-control {
    border-color: #9C8080;
  }

  .ant-form-item-control-input {
    border-color: #9C8080;
  }

  .ant-form-item-control-input-content {
    border-color: #9C8080;
  }

  .ant-select {
    border-color: #9C8080;
  }

  //.ant-select ,.ant-select-in-form-item ,.ant-select-single ,.ant-select-show-arrow,.ant-form-item-control-input-content,.ant-form-item-control-input,.ant-form-item-control,.ant-col{
  //  border-color: #9C8080;
  //}

  .ant-checkbox-wrapper-disabled {
    span {
      color: rgba(64, 64, 64, 100);
      font-size: 16px;
    }
  }

  .ant-menu-title-content {
    font-size: 20px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #9C8080;
  }

  .ant-table-tbody > tr > td {
    padding: 2px 10px;
  }

  .ant-rate-star-first, .ant-rate-star-second {
    color: grey;

    .anticon.anticon-star svg {
      width: 30px;
      height: 30px;
    }
  }

  .recharts-surface {
    width: 700px;
  }

  .customUserAdvanceModal .ant-modal-content {
    display: flex;
    background-color: rgba(192, 192, 192, 1);
  }

  .customUserAdvanceModal .anticon-info-circle {
    display: none;
  }

  .relative {
    position: relative;
  }


  @media only screen and (max-width: 600px) {
    .appContainer {
      width: 100%;
      height: 100%;
    }
  }

}


//字体声明
@font-face {
  font-family: 'MaisonNeue-Bold';
  src: url('font/MaisonNeue-Bold.ttf');
}

@font-face {
  font-family: 'MaisonNeue-BoldItalic';
  src: url('font/MaisonNeue-BoldItalic.ttf');
}

@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('font/MaisonNeue-Book.ttf');
}

@font-face {
  font-family: 'MaisonNeue-BookItalic';
  src: url('font/MaisonNeue-BookItalic.ttf');
}

@font-face {
  font-family: 'MaisonNeue-Demi';
  src: url('font/MaisonNeue-Demi.ttf');
}

@font-face {
  font-family: 'MaisonNeue-DemiItalic';
  src: url('font/MaisonNeue-DemiItalic.ttf');
}

@font-face {
  font-family: 'MaisonNeue-Light';
  src: url('font/MaisonNeue-Light.ttf');
}

@font-face {
  font-family: 'MaisonNeue-LightItalic';
  src: url('font/MaisonNeue-LightItalic.ttf');
}

@font-face {
  font-family: 'MaisonNeue-Medium';
  src: url('font/MaisonNeue-Medium.ttf');
}

@font-face {
  font-family: 'MaisonNeue-MediumItalic';
  src: url('font/MaisonNeue-MediumItalic.ttf');
}

@font-face {
  font-family: 'MaisonNeue-Mono';
  src: url('font/MaisonNeue-Mono.ttf');
}

@font-face {
  font-family: 'MaisonNeue-MonoItalic';
  src: url('font/MaisonNeue-MonoItalic.ttf');
}

@font-face {
  font-family: 'Ogg-Italic';
  src: url('font/Ogg-Italic.otf');
}

@font-face {
  font-family: 'Ogg-Roman';
  src: url('font/Ogg-Roman.otf');
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('font/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('font/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('font/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('font/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'PingFangSC-regular';
  src: url('font/PingFang Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('font/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('font/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('font/OpenSans-Light.ttf');
}

